<template>
  <div class="loading">
    <div class="loading-wrapper">
      <div class="loading__icon"><i class="icofont-spinner-alt-1"></i></div>
      <div class="loading__text">Đang xử lý, vui lòng đợi...</div>
    </div>
  </div>
</template>
<style scoped>
/* .loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.402);
  z-index: 999;
}

.loading-wrapper {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading__icon {
  width: 50px;
  height: 50px;
  padding-top: 1.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  background: -webkit-linear-gradient(rgb(255, 128, 0), #0084ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: spin 1000ms infinite linear;
  -webkit-animation: spin 1000ms infinite linear;
}

.loading__text {
  color: #ffffff;
  margin-top: 8px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
</style>
