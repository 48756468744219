<template>
  <m-page title="Báo cáo thường kỳ" :rightSlot="true">
    <template v-slot:header-right>
     
    </template>
    <template v-slot:container>
     
    </template>
    <template v-slot:footer> </template>
  </m-page>
</template>
<script>
export default {
  name: "HomePage",
  props: ["showHomePage"],
};
</script>
<style scoped>
</style>
